export default [
  {
    "gameName": "FrostPunk",
    "fileRef": "frostpunk.jpg",
  },
  {
    "gameName": "Anno 1800",
    "fileRef": "anno-1800.jpg",
  },
  {
    "gameName": "Planet Zoo",
    "fileRef": "planet-zoo.jpg",
  }
]
